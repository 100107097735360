export const APP_SERVICE_BASE_API_URL = process.env.REACT_APP_SERVICE_BASE_API_URL

// POST //
export const CREATE_TEAM = `${APP_SERVICE_BASE_API_URL}/WebCreateTeam`
export const JOIN_TEAM = `${APP_SERVICE_BASE_API_URL}/WebJoinTeam`
export const CHANGE_REQUEST = `${APP_SERVICE_BASE_API_URL}/ChangeRequestValidation`

// GET //
export const GET_TEAM = `${APP_SERVICE_BASE_API_URL}/GetTeam?teamId=`
export const GET_SIMULATIONS = `${APP_SERVICE_BASE_API_URL}/GetSimulations`
