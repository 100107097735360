const translations = {
  ChangeRequestValidationController_SimulationHasNotStarted:
    'Wait for the professor to advance the simulation for the first time so that you can make your first decisions.',
  ChangeRequestValidationController_TeamNotFound:
    'Oh no! We could not find the team, please contact us in the chat on our website.',
  ChangeRequestValidationController_TeamOutOfDate:
    'Oops, your team is out of date, please ask your professor to advance the simulation to bring your team up to date.',
  ChangeRequestValidationController_DoesntMatchRevisionNumber:
    'Simulation data had to be refreshed. Please review data and submit your change again.',
  ChangeRequestValidationController_StudentDoesntBelongTeam:
    'Oh no! We could not find the team you belong to, please contact us in the chat on our website.',
  ChangeRequestValidationController_SimulationFinished: 'Simulation already finished.',
  ChangeRequestValidationController_InvalidValue: 'Some values are invalid, please check.',
  ChangeRequestValidationController_CouldNotSaveChanges:
    "Oh no! Something went wrong and we couldn't save the changes, please contact us in the chat on our website.",
  GetTeamController_StudentHasntJoined: "You haven't joined any team yet.",
  GetTeamController_TeamNotFound:
    'Oh no! We could not find the team, please contact us in the chat on our website.',
  GetTeamController_WrongTeam:
    "Oh no! It seems you don't belong on this team, please contact us in the chat on our website.",
  GetSimulationsController_FailedToGetSimulations:
    'Oops, there was an error. Please try again, if the error persists contact us in the chat on our website.',
  CreateTeamController_SimulationNotFound:
    'Oops, it appears that the simulation you are trying to join does not exist, please contact your professor and make sure you are using the correct link.',
  CreateTeamController_SimulationIsNotAwaitingInitialization:
    'You cannot create a team in this simulation because it is running or has already finished.',
  CreateTeamController_MissingPaymentSummary:
    'It seems that the simulation you try to join has not been paid, please verify the payment with your professor.',
  CreateTeamController_ExcededLicenses:
    'Oops, there is no paid license available for you to join the simulation, please verify the payment with your professor.',
  CreateTeamController_UserAlreadyJoined: 'You already joined this simulation.',
  CreateTeamController_MissingTeamName: 'Team name is missing, please enter a valid team name.',
  CreateTeamController_TeamNameTooLong:
    "Team's name is too long, please try with a shorter name of maximum 25 characters.",
  CreateTeamController_TeamNameAlreadyInUse:
    'Oops, that team name has already been taken, we suggest using something more specific.',
  JoinTeamController_TeamNotFound:
    'Oops, it appears that the team you are trying to join does not exist, please contact your professor and make sure you are using the correct link.',
  JoinTeamController_SimulationNotFound:
    'Oops, it appears that the simulation you are trying to join does not exist, please contact your professor and make sure you are using the correct link.',
  JoinTeamController_SimulationFinished:
    "You can't join this team because the simulation is already finished.",
  JoinTeamController_MissingPaymentSummary:
    'It seems that the simulation you try to join has not been paid, please verify the payment with your professor.',
  JoinTeamController_ExcededLicenses:
    'Oops, there is no paid license available for you to join the simulation, please verify the payment with your professor.',
  JoinTeamController_UserAlreadyJoined: 'You already joined this team.',
}

const UnknownErrorMessage =
  "Wow, that didn't go as expected, please try it again. If you see this message again please contact us in the chat on our website."

export { translations, UnknownErrorMessage }
