import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Container, FormControl } from '@material-ui/core'
import ConfirmationModal from 'components/Modals/ConfirmationModal'
import ErrorModal from 'components/Modals/ErrorModal'
import ShareModal from 'components/Modals/ShareModal'
import { checkNameIsValid } from 'utilities/dataValidationUtilities'
import { loadLocalStorageValue, localStorageKeys, remove } from 'services/localstorage'
import { history } from 'store/configureStore'
import HeaderComponent from 'components/Header/HeaderComponent'
import MainTitleComponent from 'components/Title/MainTitleComponent'
import InputComponent from 'components/Input/InputComponent'
import NavigationButton from 'components/NavigationButton/NavigationButton'
import Loading from 'components/Loading'
import * as ROUTES from 'constants/routes'
import { translations, UnknownErrorMessage } from 'constants/translations'
import { createTeam, resetCreateTeamStore } from '../../actions/appBackendActions'
import './createTeamStyles.css'

class CreateTeam extends Component {
  state = {
    teamName: '',
    teamNameRequiredError: false,
    openConfirmModal: false,
    showErrorLinkMessage: false,
  }

  componentDidMount() {
    const simulationId = loadLocalStorageValue(localStorageKeys.CREATE_TEAM_SIMULATION_ID)
    if (!simulationId) {
      this.setState({
        showErrorLinkMessage: true,
      })
    }
  }

  removeOldStoredData = () => {
    remove(localStorageKeys.CREATE_TEAM_SIMULATION_ID)
  }

  setTeamName = (id, data) => {
    const isValidName = checkNameIsValid(data)
    if (isValidName) {
      this.setState({
        [id]: data,
        teamNameRequiredError: false,
        teamNameRequiredErrorText: '',
      })
    } else {
      this.setState({
        teamNameRequiredError: true,
        teamNameRequiredErrorText: "Valid Characters include: A-z, 0-9, '_', and '-'",
      })
    }
  }

  handleSubmit = () => {
    const { teamName } = this.state
    if (teamName === '') {
      this.setState({
        teamNameRequiredError: true,
        teamNameRequiredErrorText: 'Required field',
      })
    } else {
      this.handleConfirmModal()
    }
  }

  handleConfirmModal = () => {
    this.setState(prevState => {
      return { openConfirmModal: !prevState.openConfirmModal }
    })
  }

  onConfirm = () => {
    const { createTeam: backendCreateTeam } = this.props
    const { teamName } = this.state
    const postData = {
      teamName,
      simulationId: loadLocalStorageValue(localStorageKeys.CREATE_TEAM_SIMULATION_ID),
    }
    backendCreateTeam(postData)
    this.handleConfirmModal()
  }

  handleErrorModal = () => {
    this.removeOldStoredData()
    history.replace(ROUTES.SIMULATIONS)
  }

  closeErrorModal = () => {
    const { resetCreateTeamStore: resetStore } = this.props
    resetStore()
    this.setState({
      teamName: '',
    })
  }

  render() {
    const { creatingTeam, createTeamError, joinTeamLink } = this.props
    const {
      teamNameRequiredErrorText,
      teamName,
      openConfirmModal,
      showErrorLinkMessage,
      teamNameRequiredError,
    } = this.state
    const onlyOneTeamError = 'CreateTeamController_UserAlreadyJoined'
    const teamAlreadyExistError = 'CreateTeamController_TeamNameAlreadyInUse'
    const simulationStatusError = 'CreateTeamController_SimulationIsNotAwaitingInitialization'
    const missingTeamNameError = 'CreateTeamController_MissingTeamName'
    const teamNameError = 'CreateTeamController_TeamNameTooLong'
    let statusComponent = null
    let confirmationModal = null
    let errorModal = null

    const confirmationBody = (
      <p>
        {'Are you sure you want to create team '}
        <strong className="teamName">{teamName}</strong>?
      </p>
    )

    if (openConfirmModal) {
      confirmationModal = (
        <ConfirmationModal
          openConfirmModal={openConfirmModal}
          onConfirm={this.onConfirm}
          onCancel={this.handleConfirmModal}
          confirmationBody={confirmationBody}
          confirmButton="Yes"
          cancelButton="Cancel"
          headerContent="Confirmation"
        />
      )
    }

    if (joinTeamLink !== '') {
      this.removeOldStoredData()
      return (
        <ShareModal
          open
          shareLink={joinTeamLink}
          titleModal="Team created successfully!"
          titleSecondaryButton="Start Playing!"
          handleSecondaryButton={() => history.replace(ROUTES.BASE)}
          messageType
        />
      )
    }

    if (creatingTeam) {
      statusComponent = <Loading message="Creating Team..." />
    } else if (createTeamError !== '' || showErrorLinkMessage) {
      if (showErrorLinkMessage) {
        errorModal = (
          <ErrorModal
            title="There is a problem with the link"
            openErrorModal
            message="Please try enter again with the link provided by your professor"
            handleErrorModal={this.handleErrorModal}
          />
        )
      } else if (
        createTeamError === onlyOneTeamError ||
        createTeamError === simulationStatusError
      ) {
        errorModal = (
          <ErrorModal
            title=" "
            openErrorModal
            message={translations[`${createTeamError}`]}
            handleErrorModal={this.handleErrorModal}
          />
        )
      } else if (
        createTeamError === teamAlreadyExistError ||
        createTeamError === missingTeamNameError ||
        createTeamError === teamNameError
      ) {
        errorModal = (
          <ErrorModal
            title=" "
            openErrorModal
            message={translations[`${createTeamError}`]}
            handleErrorModal={this.closeErrorModal}
          />
        )
      } else {
        const translatedError = translations[`${createTeamError}`]
        errorModal = (
          <ErrorModal
            title=" "
            openErrorModal
            message={translatedError || UnknownErrorMessage}
            handleRetry={this.handleConfirmModal}
            retryText="Retry"
            hasRetry
          />
        )
      }
    }

    return (
      <Container>
        <HeaderComponent />
        <MainTitleComponent
          title="Create Team"
          subtitle="Choose a name for your team"
          hasReducedSubTitle
        />
        <div className="teamContainer">
          {confirmationModal}
          {errorModal}
          <FormControl fullWidth>
            <InputComponent
              label="Team Name"
              targetId="teamName"
              type="text"
              onError={teamNameRequiredError}
              errorMessage={teamNameRequiredErrorText}
              targetValue={teamName}
              handleChange={this.setTeamName}
            />
            <NavigationButton
              disabled={creatingTeam}
              action={this.handleSubmit}
              label="Create Team"
            />
            {statusComponent}
          </FormControl>
        </div>
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  createTeam: data => dispatch(createTeam(data)),
  resetCreateTeamStore: () => dispatch(resetCreateTeamStore()),
})

const mapStateToProps = state => ({
  creatingTeam: state.appBackend.createTeam.creatingTeam,
  createTeamError: state.appBackend.createTeam.createTeamError,
  joinTeamLink: state.appBackend.joinTeamLink,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(withRouter(CreateTeam))
