import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Container } from '@material-ui/core'
import HeaderComponent from 'components/Header/HeaderComponent'
import ShareModal from 'components/Modals/ShareModal'
import ErrorModal from 'components/Modals/ErrorModal'
import * as ROUTES from 'constants/routes'
import { translations, UnknownErrorMessage } from 'constants/translations'
import ConfirmationModal from 'components/Modals/ConfirmationModal'
import { history } from 'store/configureStore'
import Loading from 'components/Loading'
import { loadLocalStorageValue, localStorageKeys, remove } from '../../services/localstorage'
import { joinTeam } from '../../actions/appBackendActions'

class JoinTeam extends Component {
  state = {
    openConfirmModal: false,
    teamName: '',
    showErrorLinkMessage: false,
  }

  componentDidMount() {
    this.checkParams()
  }

  checkParams = () => {
    const teamName = loadLocalStorageValue(localStorageKeys.JOIN_TEAM_NAME)
    const teamId = loadLocalStorageValue(localStorageKeys.JOIN_TEAM_ID)
    if (teamName && teamId) {
      this.setState({ teamName })
      this.handleConfirmModal()
    } else {
      this.setState({
        showErrorLinkMessage: true,
      })
    }
  }

  handleConfirmModal = () => {
    this.setState(prevState => {
      return { openConfirmModal: !prevState.openConfirmModal }
    })
  }

  handleRedirection = () => {
    history.replace(ROUTES.BASE)
  }

  joinTeam = () => {
    const { joinTeam: backendJoinTeam } = this.props
    backendJoinTeam({
      teamId: loadLocalStorageValue(localStorageKeys.JOIN_TEAM_ID),
    })
    this.handleConfirmModal()
  }

  cancelJoinAction = () => {
    this.removeOldStoredData()
    history.replace(ROUTES.SIMULATIONS)
  }

  removeOldStoredData = () => {
    remove(localStorageKeys.JOIN_TEAM_ID)
    remove(localStorageKeys.JOIN_TEAM_NAME)
  }

  render() {
    const { joiningTeam, joinTeamError, joinTeamSuccessfully, joinTeamLink } = this.props
    const { teamName, showErrorLinkMessage, openConfirmModal } = this.state
    const userAlreadyRegisteredError = 'JoinTeamController_UserAlreadyJoined'
    const simulationFinishedError = 'JoinTeamController_SimulationFinished'

    let statusComponent = null
    let confirmationModal = null
    let errorModal = null

    if (joinTeamSuccessfully) {
      this.removeOldStoredData()
      return (
        <ShareModal
          open={joinTeamLink !== ''}
          shareLink={joinTeamLink}
          titleModal={`You've successfully joined team: ${teamName}`}
          titleSecondaryButton="Start Playing!"
          handleSecondaryButton={() => history.replace(ROUTES.BASE)}
          messageType
        />
      )
    } else if (joiningTeam) {
      statusComponent = <Loading message="Joining team..." />
    } else if (joinTeamError || showErrorLinkMessage) {
      if (showErrorLinkMessage) {
        errorModal = (
          <ErrorModal
            title="There is a problem with the link"
            openErrorModal
            message="Please try enter again with the link provided by your teammate"
            handleErrorModal={this.cancelJoinAction}
          />
        )
      } else if (
        joinTeamError === userAlreadyRegisteredError ||
        joinTeamError === simulationFinishedError
      ) {
        errorModal = (
          <ErrorModal
            title=" "
            openErrorModal
            message={translations[`${joinTeamError}`]}
            handleErrorModal={this.cancelJoinAction}
          />
        )
      } else {
        const translatedError = translations[`${joinTeamError}`]
        errorModal = (
          <ErrorModal
            title=" "
            openErrorModal
            message={translatedError || UnknownErrorMessage}
            handleRetry={this.handleConfirmModal}
            retryText="Retry"
            hasRetry
          />
        )
      }
    }

    const confirmationBody = (
      <p>
        {'Are you sure you want to join team '}
        <strong className="teamName">{teamName}</strong>?
      </p>
    )

    if (openConfirmModal) {
      confirmationModal = (
        <ConfirmationModal
          openConfirmModal={openConfirmModal}
          onConfirm={this.joinTeam}
          onCancel={this.cancelJoinAction}
          confirmationBody={confirmationBody}
          confirmButton="Yes"
          cancelButton="Cancel"
          headerContent="Confirmation"
        />
      )
    }

    return (
      <Container>
        {confirmationModal}
        <HeaderComponent />
        {statusComponent}
        {errorModal}
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  joinTeam: data => dispatch(joinTeam(data)),
})

const mapStateToProps = state => ({
  joiningTeam: state.appBackend.joinTeam.joiningTeam,
  joinTeamError: state.appBackend.joinTeam.joinTeamError,
  joinTeamSuccessfully: state.appBackend.joinTeam.joinTeamSuccessfully,
  joinTeamLink: state.appBackend.joinTeamLink,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(withRouter(JoinTeam))
