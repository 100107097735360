import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#274A6E',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#274A6E',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#274A6E',
      },
    },
  },
})(TextField)

const InputComponent = props => {
  const { handleChange, label, onError, errorMessage, targetId, type, targetValue } = props

  const handleInputChange = event => {
    const { id, value } = event.target
    const data = value
    handleChange(id, data)
  }

  return (
    <CustomTextField
      id={targetId}
      type={type}
      value={targetValue}
      error={onError}
      variant="outlined"
      helperText={errorMessage}
      size="medium"
      onChange={event => handleInputChange(event)}
      label={label}
      margin="dense"
    />
  )
}
export default InputComponent
