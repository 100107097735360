import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Container, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import HeaderComponent from 'components/Header/HeaderComponent'
import MainTitleComponent from 'components/Title/MainTitleComponent'
import MessagesComponent from 'components/Message/MessagesComponent'
import ErrorModal from 'components/Modals/ErrorModal'
import * as ROUTES from 'constants/routes'
import { translations, UnknownErrorMessage } from 'constants/translations'
import { history } from 'store/configureStore'
import Loading from 'components/Loading'
import moment from 'moment'
import { getSimulations, getTeam } from '../../actions/appBackendActions'
import './simulationsStyles.css'

const StyledTableCell = withStyles({
  head: {
    backgroundColor: '#0379ba',
    color: '#fff',
  },
})(TableCell)

class Simulations extends Component {
  state = {
    openErrorModal: false,
    errorMessage: '',
  }

  componentDidMount() {
    const { getSimulations: getStudentSimulations } = this.props
    getStudentSimulations()
  }

  handleSimulation = teamId => {
    this.setState({ teamId })
    this.goToPage(teamId)
  }

  goToPage = async teamId => {
    const response = await getTeam(teamId)
    if (response.outcome) {
      history.replace(ROUTES.BASE)
    } else {
      this.setState({ openErrorModal: true, errorMessage: response.message })
    }
  }

  handleErrorModal = () => {
    this.setState({ openErrorModal: false, errorMessage: '' })
    const { teamId } = this.state
    this.goToPage(teamId)
  }

  dateFormatter = date => {
    return moment(date).format(`MMMM D, YYYY [at] hh:mm A`)
  }

  render() {
    const { isLoading, simulationsError, simulationsData, userFullName } = this.props
    const { openErrorModal, errorMessage } = this.state

    let mainComponent = null

    if (isLoading) {
      mainComponent = <Loading message="Loading Simulations..." />
    } else if (simulationsError !== '') {
      const translatedError = translations[`${simulationsError}`]
      mainComponent = (
        <div className="message">
          <MessagesComponent message={translatedError || UnknownErrorMessage} />
        </div>
      )
    } else if (simulationsData && simulationsData.length > 0) {
      mainComponent = (
        <Table component={Paper}>
          <TableHead>
            <TableRow className="header">
              <StyledTableCell>Simulation</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Start Time</StyledTableCell>
              <StyledTableCell>Team Name</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {simulationsData.map(simulation => (
              <TableRow
                hover
                key={simulation.simulationName}
                onClick={() => this.handleSimulation(simulation.teamId)}
              >
                <TableCell>{simulation.simulationName}</TableCell>
                <TableCell>{simulation.status}</TableCell>
                <TableCell>
                  {simulation.startTime
                    ? this.dateFormatter(simulation.startTime)
                    : 'Pending start'}
                </TableCell>
                <TableCell>{simulation.teamName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    } else {
      mainComponent = (
        <div className="message">
          <MessagesComponent message="You have not joined any simulation yet" info />
        </div>
      )
    }

    return (
      <Container>
        <HeaderComponent showLogoutButton />
        <MainTitleComponent title="Simulations" subtitle={userFullName} />
        <div className="homeMiddle">{mainComponent}</div>
        <ErrorModal
          title="Error Getting Team"
          openErrorModal={openErrorModal}
          message={errorMessage}
          handleRetry={this.handleErrorModal}
          retryText="Retry"
          hasRetry
        />
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getSimulations: () => dispatch(getSimulations()),
})

const mapStateToProps = state => ({
  isLoading: state.appBackend.getSimulations.loading,
  simulationsError: state.appBackend.getSimulations.errorMessage,
  simulationsData: state.appBackend.getSimulations.simulations,
  userFullName: state.user.userProfile.fullName,
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(withRouter(Simulations))
